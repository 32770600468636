import React from 'react';
import { createRoot } from 'react-dom/client'; 
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize('G-42K7JZHBME');

const container = document.getElementById('root'); 
const root = createRoot(container);

root.render(
	<BrowserRouter basename={process.env.PUBLIC_URL}>
		<App />
	</BrowserRouter>
);