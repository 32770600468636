import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './Layout.scss';

function Layout({ children, className, title, breadcrumbs }) {
	const location = useLocation();
	const contentRef = useRef(null);
	const layoutClassName = `sub_page ${className || ''}`.trim();

	useEffect(() => {
		if (contentRef.current) {
			setTimeout(() => {
				contentRef.current.classList.add('on');
			}, 300);
		}
	}, [location]);
	

	return (
		<div className={layoutClassName}>
			<div className='visual'>
				<div className='inner'>
					{title && <h2 className='main_title'>{title}</h2>}
					{breadcrumbs && (
						<ul className='breadcrumb-list'>
							{breadcrumbs.map((crumb, index) => (
								<li className={`breadcrumb-item ${index === breadcrumbs.length - 1 ? 'active' : ''}`} key={index}>
									{crumb.title}
								</li>
							))}
						</ul>
					)}
				</div>
			</div>
			<div className='content' ref={contentRef}>
				<div className='inner'>{children}</div>
			</div>
		</div>
	);
}

export default Layout;
