import { useState, useEffect } from 'react';

function useFetchJsonData(url) {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		fetch(url)
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}
				return response.json();
			})
			.then((data) => {
				setData(data);
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error loading the JSON data: ', error);
				setError(error);
				setLoading(false);
			});
	}, [url]); // URL이 변경되면 훅을 다시 실행합니다.

	return { data, loading, error };
}

export default useFetchJsonData;
