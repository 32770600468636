import './About.scss';
import Layout from '../../common/Layout/Layout';

export default function About() {
	const breadcrumbs = [{ title: 'COMPANY' }, { title: 'About Us' }];
	return (
		<Layout className='about' title='About us' breadcrumbs={breadcrumbs}>
			<div className='left_cont'>
				{/* <h2 className='title'>기업 소개</h2> */}
				<p className='txt_desc'>
					엑스원패스트(주) 는 선도적인 ERP 솔루션을 제공하는 컨설팅 전문 기업입니다. <br /> <br />
					기업이 성장할수록 데이터 정보화를 통한 비즈니스 프로세스를 개선하고자 하는 요구는 지속적으로 증가하고 있습니다. 이에, 끊임없는 개선과 변화가 필수인 정보화 시대에서 수많은 정보의 지식화를
					통해 전략적 의사결정 시행 체제를 갖추는 것은 매우 중요합니다.
					<br />
					<br /> 우리는 세밀한 고객 니즈 분석을 통해 세부적인 업무 프로세스 분석을 바탕으로 한 비즈니스 청사진 설계, <br />
					ERP 시스템 구축 및 운영에 이르는 One Platform Total Service를 제공합니다. 더불어 경영 효율성을 향상시키기 위한 서비스 역량을 보유하고 점차 발전해 나가고 있습니다. <br />
					이를 바탕으로 각 업무 영역을 최적화하고, 실시간 데이터 통합 및 분석 기능을 강화하여 신속 정확한 의사결정이 가능하도록 합니다. <br /> <br />
					고객 맞춤형 최적의 솔루션 제공을 통해 산업 내 경쟁 우위를 확보하고, 나아가 효율적인 지속 가능경영을 펼칠 수 있도록 우리가 함께하겠습니다.
					<br /> <br />
					엑스원패스트(주)는 앞으로도 미래지향적 관점으로 끊임없이 발전하며 언제나 고객과 함께 동반 성장하는 든든한 파트너가 되겠습니다. <br /> <br />
					감사합니다.
				</p>
			</div>
			<div className='right_cont'></div>
		</Layout>
	);
}
