import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { EffectFade, Autoplay, Pagination, Navigation } from "swiper/modules";
import "./Visual.scss";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import { Link } from "react-router-dom";

export default function Visual() {
    const [activeIndex, setActiveIndex] = useState(0);
    const figureRef = useDynamicHeight();
    const [isActive, setIsActive] = useState(false);
    const serviceContRef = useRef(null);
    const conBoxRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= serviceContRef.current.offsetTop * 0.7) {
                setIsActive(true);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (isActive) {
            const lis = conBoxRef.current.querySelectorAll("li");
            lis.forEach((li, index) => {
                setTimeout(() => {
                    li.classList.add("on");
                }, 100 * index);
            });
        } else {
            const lis = conBoxRef.current.querySelectorAll("li");
            lis.forEach((li) => {
                li.classList.remove("on");
            });
        }
    }, [isActive]);

    return (
        <>
            <figure ref={figureRef}>
                <Swiper
                    // spaceBetween={30}
                    loop={true}
                    effect={"fade"}
                    centeredSlides={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[EffectFade, Pagination, Navigation, Autoplay]}
                    className="mySwiper"
                    onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                >
                    <SwiperSlide className="slide01">
                        <div className="txt_box">
                            <h2>
                                선도적인 ERP 솔루션을 제공하는
                                <br className="mo_ver" /> 컨설팅 전문 기업
                            </h2>
                            <p>
                                늘 고객의 목소리에 귀 기울이며 고객과 함께
                                성장하고,
                                <br className="mo_ver" /> 지속 가능한 미래를
                                만들어 갈 수 있도록 노력하는 기업이 되겠습니다.
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="slide02">
                        <div className="txt_box">
                            <h2 className="en">One Platform Total Service</h2>
                            <p>
                                논스톱 통합 서비스로 시스템 운영을 간소화하고,
                                <br className="mo_ver" /> 업무 효율을 극대화하여
                                시장 변화에 신속 대응합니다.
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="slide03">
                        <div className="txt_box">
                            <h2>
                                고객의 비즈니스 경쟁력과 기업 가치를
                                <br className="mo_ver" /> 향상시키는 최고의 ERP
                                컨설팅
                            </h2>

                            <p>
                                고객 맞춤형 최적의 솔루션 제공을 통해 산업 내
                                경쟁 우위를 확보하고, <br className="mo_ver" />
                                나아가 효율적인 지속 가능경영을 펼칠 수 있도록
                                당사가 함께하겠습니다.
                            </p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </figure>

            <div
                ref={serviceContRef}
                className={`service_cont ${isActive ? "on" : ""}`}
            >
                <div className="inner">
                    <div className="txt_box">
                        <h2 className="en">SERVICE</h2>
                        <p>One Platform Total Service를 제공하는 선도 기업</p>
                    </div>
                    <div ref={conBoxRef} className="con_box">
                        <ul>
                            <li>
                                <Link to="/erp">
                                    <div className="img_box">
                                        <div className="img_wrap">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/img/ERP.png`}
                                                alt="전사적 자원 관리"
                                            />
                                        </div>
                                    </div>
                                    <div className="txt_box">
                                        <h3>전사적 자원 관리</h3>
                                        <p>(Enterprise Resource Planning)</p>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/eis">
                                    <div className="img_box">
                                        <div className="img_wrap">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/img/EIS.png`}
                                                alt="경영자 정보 시스템"
                                            />
                                        </div>
                                    </div>
                                    <div className="txt_box">
                                        <h3>경영자 정보 시스템</h3>
                                        <p>(Executive Information System)</p>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/scm">
                                    <div className="img_box">
                                        <div className="img_wrap">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/img/SCM.png`}
                                                alt="공급망 관리"
                                            />
                                        </div>
                                    </div>
                                    <div className="txt_box">
                                        <h3>공급망 관리</h3>
                                        <p>(Supply Chain Management)</p>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/ito">
                                    <div className="img_box">
                                        <div className="img_wrap">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/img/ITO.png`}
                                                alt="IT 아웃소싱"
                                            />
                                        </div>
                                    </div>
                                    <div className="txt_box">
                                        <h3>IT 아웃소싱</h3>
                                        <p>(IT Outsourcing)</p>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
