import Visual from '../visual/Visual';

function MainWrap() {
	return (
		<main>
			<Visual />
		</main>
	);
}

export default MainWrap;
