import Layout from '../../common/Layout/Layout';
import './Mission.scss';

export default function Mission() {
	const breadcrumbs = [
		{ title: 'COMPANY' },
		{ title: 'mission & vision' }, // 현재 페이지, href 없음
	];
	return (
		<Layout className='mission' title='Mission / Vision' breadcrumbs={breadcrumbs}>
			<div className='circle_cont'>
				<div className='circle left'>
					<h3 className='en'>OUR MISSION</h3>
					<p className='en'>
						<strong>
							전문적인 기술 및 변화에
							<br /> 대한 민첩 대응
						</strong>{' '}
						<br />
						<br />
						(Professional skills and
						<br /> agile response to change)
					</p>
				</div>
				<div className='circle right'>
					<h3 className='en'>OUR VISION</h3>
					<p className='en'>
						<strong>
							진정한 파트너십을 통한 <br />
							고객과의 동반 성장
						</strong>{' '}
						<br />
						<br />
						(Shared growth with customers
						<br /> through true partnerships)
					</p>
				</div>
			</div>
			<h4>
				당사는 <strong>지속적인 혁신</strong>을 통해 고객과 함께 <strong>성장하는 미래</strong>를 창조합니다.
			</h4>
		</Layout>
	);
}
