import React from 'react';
import { NavLink } from 'react-router-dom';
// import useScrollPosition from '../../../hooks/useScrollTab';
import { HashLink } from 'react-router-hash-link';

function GnbMobile({ isActive, setIsActive }) {
	// const { activeTab, scrollToSection } = useScrollPosition();

	const handleClick = (index) => {
		setIsActive(false);
		// scrollToSection(index);
	};

	// Adjust the scroll behavior
	const customScroll = (el) => {
		setTimeout(() => {
			// 비동기 콘텐츠가 로드될 시간을 주기 위해 setTimeout 사용
			const headerHeight = document.querySelector('header')?.clientHeight || 0;
			const tabContHeight = document.querySelector('.tab_cont')?.clientHeight || 0;
			const yOffset = -(headerHeight + tabContHeight); // 필요한 경우 추가적인 오프셋을 적용할 수 있습니다.
			const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

			window.scrollTo({ top: y, behavior: 'smooth' });
		}, 700); // 300ms 대기. 실제 로딩 시간에 따라 조절이 필요할 수 있습니다.
	};

	return (
		<div className={`gnb_mo ${isActive ? 'on' : ''}`}>
			<nav>
				<ul className='depth1'>
					<li>
						<NavLink to='/about' onClick={handleClick}>
							COMPANY
						</NavLink>
						<div className='depth2'>
							<ul>
								<li>
									<NavLink to='/about' onClick={handleClick}>
										About us
									</NavLink>
								</li>
								<li>
									<NavLink to='/ceo' onClick={handleClick}>
										CEO Message
									</NavLink>
								</li>
								<li>
									<NavLink to='/mission' onClick={handleClick}>
										Mission / Vision
									</NavLink>
								</li>
								<li>
									<NavLink to='/location' onClick={handleClick}>
										Location
									</NavLink>
								</li>
							</ul>
						</div>
					</li>
					<li>
						<NavLink to='/erp' onClick={handleClick}>
							SERVICE
						</NavLink>
						<div className='depth2'>
							<ul>
								<li>
									<NavLink to='/erp' onClick={handleClick}>
										ERP
									</NavLink>
								</li>
								<li>
									<NavLink to='/eis' onClick={handleClick}>
										EIS
									</NavLink>
								</li>
								<li>
									<NavLink to='/scm' onClick={handleClick}>
										SCM
									</NavLink>
								</li>
								<li>
									<NavLink to='/ito' onClick={handleClick}>
										ITO
									</NavLink>
								</li>
							</ul>
						</div>
					</li>
					<li>
						<NavLink to='/manufacturing' onClick={handleClick}>
							REFERENCE
						</NavLink>
						<div className='depth2'>
							<ul>
								<li>
									<HashLink to='/manufacturing#manufacturing' scroll={customScroll} onClick={handleClick}>
										Manufacturing
									</HashLink>
								</li>
								<li>
									<HashLink to='/manufacturing#distribution' scroll={customScroll} onClick={handleClick}>
										Distribution
									</HashLink>
								</li>
								<li>
									<HashLink to='/manufacturing#etc' scroll={customScroll} onClick={handleClick}>
										ETC
									</HashLink>
								</li>
							</ul>
						</div>
					</li>
					<li>
						<NavLink to='/contact' onClick={handleClick}>
							CONTACT
						</NavLink>
					</li>
				</ul>
			</nav>
		</div>
	);
}

export default GnbMobile;