import Layout from '../../common/Layout/Layout';
import './Ceo.scss';

export default function Ceo() {
	const breadcrumbs = [
		{ title: 'COMPANY' },
		{ title: 'CEO message' }, // 현재 페이지, href 없음
	];
	return (
		<Layout className='ceo' title='CEO Message' breadcrumbs={breadcrumbs}>
			<div className='left_cont'></div>
			<div className='right_cont'>
				<p class='txt_desc'>
					{/* 안녕하십니까? <br /> */}
					{/* <br /> */}
					<span className='title'>
						<strong>고객의 비즈니스 경쟁력</strong>과 <strong>기업 가치</strong>를 향상시키는
						<br className='mo_ver' /> 최고의 ERP 컨설팅 기업을 지향합니다.
					</span>
					<br />
					<br />
					현재의 언택트 시대, 포스트 코로나 시대에 가장 효율적으로 업무하기 위한 방법은 솔루션과 협업 툴을 도입하는 것입니다. 급변하는 업무와 디지털 환경에서 지속적으로 기술이 발전하고 진화함에 따라
					ERP 도입의 필요성은 더욱 강조되고 있습니다. 우리는 이러한 경영 환경의 변화에 앞선 기술력과 차별화된 서비스로, 빠르고 유연하게 대응할 수 있는
					<span className='main_c'> 'Change-sensitive Business Consulting For the Future'</span>
					를 제공하겠습니다. <br />
					<br />
					1. 엑스원패스트(주)는 전략적 파트너십을 형성하여 상호 발전을 위한 지속적인 동행을 목표로 합니다.
					<br />
					- 개방적이고 정직한 커뮤니케이션을 통해 고객과의 안정적인 관계를 형성하고 지속 가능한 비즈니스 성장의 기반을 마련합니다.
					<br />
					<br /> 2. 엑스원패스트(주) 는 뛰어난 서비스와 솔루션으로 높은 고객 가치를 실현합니다. <br />
					- 믿음과 신뢰를 바탕으로 차별화된 서비스 제공을 통해 높은 고객 만족도를 이끌어냅니다.
					<br />
					<br /> 3. 엑스원패스트(주) 는 고객의 니즈를 반영한 명확한 설계 프로세스를 제공합니다.
					<br />
					- 고객과의 꾸준한 소통을 통해 고객 목표 달성을 위한 세부적인 PROCESS를 제공합니다.
					<br />
					<br /> 엑스원패스트(주)는 늘 고객의 목소리에 귀 기울이며, 고객과 함께 성장하고 지속 가능한 미래를 만들어 갈 수 있도록 노력하는 기업이 되겠습니다. <br />
					<br />
					{/* 많은 관심과 격려 부탁드립니다. <br /	> */}
					<br />
					{/* 감사합니다. */}
					{/* <br /> */}
					{/* <br /> */}
					<span className='ceo_name'>대표이사 &nbsp; &nbsp;&nbsp; 이 창 영</span>
				</p>
			</div>
		</Layout>
	);
}
