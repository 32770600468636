import './Footer.scss';
import { NavLink, Link } from 'react-router-dom';

export default function Footer() {
	const logoPath = `${process.env.PUBLIC_URL}/img/logo.png`;

	return (
		<footer>
			<div className='inner'>
				<div className='left_info'>
					<p>
						<span className='company_name'>엑스원패스트(주)</span>
						<span>대표 : 이창영</span>
						<span>
							주소 : 서울시 성동구 아차산로7길 15-1 <br className='mo_ver' />
							<span>(성수동2가, 제이제이빌딩) 3층 345호</span>
						</span>
					</p>
					<p>
						<span>
							대표전화 : <a href='tel:02-6925-0890'>02-6925-0890</a>
						</span>
						<span>
							이메일 : <a href='mailto:help@exonefast.com'>help@exonefast.com</a>
						</span>
					</p>
				</div>
				<div className='right_info'>
					<h1 className='logo'>
						<Link to='/'>
							<img src={logoPath} alt='logo' />
						</Link>
					</h1>
					<span>©EXONEFAST All rights reserved.</span>
				</div>
			</div>
		</footer>
	);
}
