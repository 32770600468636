import './Location.scss';
import Layout from '../../common/Layout/Layout';

export default function Location() {
	const breadcrumbs = [{ title: 'COMPANY' }, { title: 'location' }];
	return (
		<Layout className='location' title='Location' breadcrumbs={breadcrumbs}>
			<iframe
				src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.3645055197144!2d127.05189227629599!3d37.54647512527742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca49646b49c11%3A0x30b1f71b2992db38!2z7ISc7Jq47Yq567OE7IucIOyEseuPmeq1rCDslYTssKjsgrDroZw36ri4IDE1LTE!5e0!3m2!1sko!2skr!4v1709642740133!5m2!1sko!2skr'
				width='100%'
				height='450'
				allowFullScreen=''
				loading='lazy'
				referrerPolicy='no-referrer-when-downgrade'
			></iframe>

			<div className='loaction_cont'>
				<dl>
					<dt>주소</dt>
					<dd>서울 성동구 아차산로7길 15-1 (성수동2가, 제이제이빌딩) 3층 345호</dd>
				</dl>
				<dl>
					<dt>전화번호</dt>
					<dd>TEL : 02-6925-0890</dd>
				</dl>
				<dl>
					<dt>대중교통</dt>
					<dd>2호선 성수역 1번 출구에서 도보 4분</dd>
				</dl>
				<dl>
					<dt>이메일</dt>
					<dd>help@exonefast.com</dd>
				</dl>
			</div>
		</Layout>
	);
}
