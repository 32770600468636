import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/common/header/Header';
import Footer from './components/common/footer/Footer';
import About from './components/sub/about/About';
import Erp from './components/sub/erp/Erp';
import Eis from './components/sub/eis/Eis';
import Ceo from './components/sub/ceo/Ceo';
import Scm from './components/sub/scm/Scm';
import Ito from './components/sub/ito/Ito';
import Contact from './components/sub/contact/Contact';
import MainWrap from './components/main/mainWrap/MainWrap';
import Location from './components/sub/location/Location';
import Mission from './components/sub/mission/Mission';
import Manufacturing from './components/sub/manufacturing/Manufacturing';
import './styles/Global.scss';
import { useMedia } from './hooks/useMedia';
import ScrollToTop from './components/common/Layout/ScrollToTop';
import usePageTracking from './usePageTracking';

function App() {
	usePageTracking();

	const location = useLocation();
	const isHomePage = location.pathname === '/';
	const deviceType = useMedia();

	let mainClass = isHomePage ? 'main' : 'sub';
	if (deviceType) {
		mainClass += ` ${deviceType}`;
	}

	return (
		<main className={mainClass}>
			<ScrollToTop />
			<Header />
			<Routes>
				<Route exact path='/' element={<MainWrap />} />
				<Route path='/about' element={<About />} />
				<Route path='/ceo' element={<Ceo />} />
				<Route path='/mission' element={<Mission />} />
				<Route path='/location' element={<Location />} />
				<Route path='/erp' element={<Erp />} />
				<Route path='/eis' element={<Eis />} />
				<Route path='/scm' element={<Scm />} />
				<Route path='/ito' element={<Ito />} />
				<Route path='/contact' element={<Contact />} />
				<Route path='/manufacturing' element={<Manufacturing />} />
			</Routes>
			<Footer />
		</main>
	);
}

export default App;