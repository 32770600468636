import Layout from '../../common/Layout/Layout';
import './Scm.scss';
import useFetchJsonData from '../../../hooks/useFetchJsonData';

export default function Erp() {
	const breadcrumbs = [{ title: 'service' }, { title: 'scm' }];
	const { data, loading, error } = useFetchJsonData('/DB/scmEffectsData.json');
	// 데이터 로딩 중
	if (loading) {
		return <div>Loading...</div>;
	}

	// 데이터 로딩 에러
	if (error) {
		return <div>Error: {error.message}</div>;
	}
	return (
		<Layout className='erp scm' title='SCM' breadcrumbs={breadcrumbs}>
			<div className='erp_cont'>
				<h2 className='title'>SCM 소개</h2>
				<p>
					<span className='main_c'>SCM (Supply Chain Management)</span> 란 부품 제공업자로부터 생산자, 배포자, 고객에 이르는 물류의 흐름을 하나의 가치사슬 관점에서 파악하고 필요한 정보가 원활히
					흐르도록 지원하는 시스템을 의미합니다. 즉, 원자재 조달의 초기 단계에서부터 마지막 단계인 제품 배송에 이르기까지 제품 또는 서비스와 관련된 상품, 데이터 및 재정의 흐름을 전체적으로 관리합니다.
					이를 통해 비용을 최소화하고 효율성을 극대화하는 것이 최종 목표입니다.
				</p>
			</div>
			<div className='erp_cont'>
				<h2 className='title'>SCM 효율성</h2>
				<div className='erp_cont_desc'>
					<div className='txt_desc'>
						<p>
							<strong>용이한 수요 예측 및 계획 설정이 가능합니다. </strong> 과거의 데이터와 통계 자료를 통해 향후 발생될 수요를 예측하고 이를 기반으로 보다 세밀한 생산 계획을 수립하여 생산성을 최대화
							할 수 있습니다.
						</p>
						<p>
							<strong>보다 효과적인 비용 절감과 시간 단축이 가능합니다. </strong> 예를 들어, 적절한 재고 관리를 통해 저장 공간, 보관 비용 등을 절감할 수 있고 물류 최적화를 통해 운송 비용을 감소시킬 수
							있습니다. 또한 생산 및 유통 시간까지 줄일 수 있어 중요한 시장 경쟁력을 확보할 수 있습니다.
						</p>
						<p>
							<strong>고객 서비스 제고가 가능합니다.</strong> 효율적인 공급망 관리를 통해 고객에게 제품 및 서비스를 신속 정확하게 제공함으로써 고객 만족도를 높일 수 있습니다. 이를 기반으로
							장기적으로는 브랜드 가치와 충성도까지 증가될 수 있습니다.
						</p>
						<p>
							<strong>체계적인 위험 관리가 가능합니다. </strong>
							원자재 가격 변동, 공급자 파산, 물류 이슈 등의 다양한 위험 요소들을 세부적으로 관리하고 관련 대응 방안을 마련하고 실행할 수 있습니다.
						</p>
						<p>
							<strong>급변하는 시장에서 빠르고 유연한 대응이 가능합니다. </strong>
							시장 조건이나 고객의 수요가 끊임없이 변화하는 현 시대에서 신규 원자재 공급처 긴급 물색, 급증하는 주문에 대한 긴급 대응 등 상황에 맞는 적절한 대처가 가능합니다.
						</p>
					</div>
					<div className='img_desc'></div>
				</div>
			</div>
			<div className='erp_cont'>
				<h2 className='title'>SCM 기대 효과</h2>
				<div className='info_box_all'>
					{data?.scmEffects.map((effect, index) => (
						<div className='info_box' key={index}>
							<h3>{effect.title}</h3>
							<p>{effect.description}</p>
						</div>
					))}
				</div>
			</div>
		</Layout>
	);
}
