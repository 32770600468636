import { useEffect, useRef } from 'react';

// 커스텀 훅 정의
const useDynamicHeight = () => {
	const ref = useRef(null);

	const updateFigureHeight = () => {
		if (ref.current) {
			let vh = window.innerHeight * 0.01;
			ref.current.style.height = `${vh * 100}px`;
		}
	};

	useEffect(() => {
		updateFigureHeight();
		window.addEventListener('resize', updateFigureHeight);

		return () => {
			window.removeEventListener('resize', updateFigureHeight);
		};
	}, []);

	return ref;
};

export default useDynamicHeight;
