import './Manufacturing.scss';
import Layout from '../../common/Layout/Layout';
import useScrollPosition from '../../../hooks/useScrollTab';
import { HashLink } from 'react-router-hash-link';

export default function Manufacturing() {
	const breadcrumbs = [{ title: 'references' }, { title: '' }];

	const logoPath = `${process.env.PUBLIC_URL}/img/logo01.png`;
	const logoPath2 = `${process.env.PUBLIC_URL}/img/logo02.png`;
	const logoPath3 = `${process.env.PUBLIC_URL}/img/logo03.png`;
	const logoPath4 = `${process.env.PUBLIC_URL}/img/logo04.jpg`;
	const logoPath5 = `${process.env.PUBLIC_URL}/img/logo05.jpg`;
	const logoPath6 = `${process.env.PUBLIC_URL}/img/logo06.png`;
	const logoPath7 = `${process.env.PUBLIC_URL}/img/logo07.png`;
	const logoPath8 = `${process.env.PUBLIC_URL}/img/logo08.jpg`;
	const logoPath9 = `${process.env.PUBLIC_URL}/img/logo09.jpg`;
	const logoPath10 = `${process.env.PUBLIC_URL}/img/logo18.webp`;
	const logoPath11 = `${process.env.PUBLIC_URL}/img/logo15.png`;
	const logoPath12 = `${process.env.PUBLIC_URL}/img/logo08.png`;
	const logoPath13 = `${process.env.PUBLIC_URL}/img/logo17.png`;
	const logoPath14 = `${process.env.PUBLIC_URL}/img/logo18.webp`;
	const logoPath15 = `${process.env.PUBLIC_URL}/img/logo19.png`;
	const logoPath16 = `${process.env.PUBLIC_URL}/img/logo20.jpg`;
	const logoPath17 = `${process.env.PUBLIC_URL}/img/logo21.png`;
	const logoPath18 = `${process.env.PUBLIC_URL}/img/logo22.png`;
	const logoPath19 = `${process.env.PUBLIC_URL}/img/logo23.png`;
	const logoPath20 = `${process.env.PUBLIC_URL}/img/logo24.png`;

	const { activeTab, isTabContFixed, isContentDescOn } = useScrollPosition();

	const sectionNames = [
		{ ko: '제조', en: 'manufacturing' },
		{ ko: '유통', en: 'distribution' },
		{ ko: '기타', en: 'etc' },
	];

	return (
		<Layout className='sub_page manufacturing' title='REFERENCE' breadcrumbs={breadcrumbs}>
			<div className={`tab_cont ${isTabContFixed ? 'fixed' : ''}`}>
				<ul>
					{sectionNames.map((section, index) => (
						<li key={index} className={activeTab === index ? 'on' : ''}>
							<HashLink
								to={`#${section.en}`}
								scroll={(el) => {
									const headerHeight = document.querySelector('header')?.clientHeight || 0;
									const tabContHeight = document.querySelector('.tab_cont')?.clientHeight || 0;
									const yOffset = -(headerHeight + tabContHeight);
									const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

									window.scrollTo({ top: y, behavior: 'smooth' });
								}}
							>
								{section.ko}
							</HashLink>
						</li>
					))}
				</ul>
			</div>

			<div className={`content_desc ${isContentDescOn ? 'on' : ''}`}>
				<div className='myScroll' id='manufacturing'>
					<h2>[제조]</h2>
					<p className='title_desc'>
						생산 정보를 시스템화 함으로써 제품의 품질 및 생산성 향상에 기여하도록 합니다. <br />
						이는 제조원가, 공정 수율 관리 부문에 사용되며, 더불어 판매 및 생산계획에 근거하여 안정적인 생산을 위한 자재 조달 및 구매 실적 분석을 통한 제조 원가 절감 기회를 도출합니다.
					</p>
					<div className='cont_1'>
						<div className='product_cont_all'>
							<div className='product_cont'>
								<p className='title'>자동차 부품 </p>
								<p className='title_desc'>
									BOM 관리, 생산성 향상 및 타이트한 납기 관리를 통해 적시 적량의 부품 공급이 가능합니다.
									<br />
									이를 바탕으로 완성차의 5 STAR, SQ 인증 등에 대응하여 협력사 인증 평가에서 우수한 성과를 기대할 수 있습니다.
								</p>
								<div className='partners'>
									<div className='partners_desc'>
										<div className='cont_box'>
											<div className='img_box'>
												<img src={logoPath} alt='logo' />
											</div>
											<p>
												하나의 SAP Business One 시스템 안에서 업종이 전혀 다른 3개의 사업부문
												<br className='pc_ver' />
												(자동차 부품 제조/플라스틱 용기 제조/윤활유 유통)을 구축
											</p>
										</div>
										<div className='info_desc'>
											<ul>
												<li>
													<span>1)</span> <p>VMI(Vendor Management Inventory) 관리 및 매출</p>
												</li>
												<li>
													<span>2)</span> <p>생산자재 투입에 대한 자동 선입선출 관리(Lot 연계)</p>
												</li>
												<li>
													<span>3)</span> <p>SCM 시스템 구축 및 구매입고 연계</p>
												</li>
												<li>
													<span>4)</span> <p>QR Code를 이용한 업무 프로세스 수립과 이를 기반으로 하는 생산/물류 시스템 구현</p>
												</li>
												<li>
													<span>5)</span> <p>임가공 관리, 단가소급</p>
												</li>
												<li>
													<span>6)</span> <p>예정(부품)원가명세서</p>
												</li>
											</ul>
										</div>
									</div>
									<div className='partners_desc'>
										<div className='cont_box'>
											<div className='img_box'>
												<img src={logoPath2} alt='logo' />
											</div>
											<p>
												현대자동차그룹의 핵심 부품 계열사로써, 해외법인 (중국, 브라질, 멕시코)의 <br className='pc_ver' />
												SAP Business one ERP 구축
											</p>
										</div>
										<div className='info_desc'>
											<ul>
												<li>
													<span>1)</span> <p>그룹사 Global 표준 프로세스 수립 및 해외법인 적용</p>
												</li>
												<li>
													<span>2)</span>{' '}
													<p>
														판매 주문~매출 정산, 구매 발주~매입 정산, 생산~제조원가 및 손익을 아우르는 ERP 전체
														<br className='mo_ver' /> 프로세스 구현
													</p>
												</li>
												<li>
													<span>3)</span> <p>국가별 언어 및 현지화 프로세스를 반영</p>
												</li>
												<li>
													<span>4)</span> <p>발주 시스템, MES 연계</p>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='product_cont_all'>
							<div className='product_cont'>
								<p className='title'>장비 및 부품 </p>
								<p className='title_desc'>
									생산 공정을 세부적으로 관리하여 시스템화 함으로써 정확성 있는 생산 실적 정보를 얻을 수 있습니다. <br />
									그리고 실시간 재고 추적을 통해 재고 흐름을 빠르게 파악하여 적정 재고 수준을 최적화할 수 있습니다. 
								</p>
								<div className='partners'>
									<div className='partners_desc'>
										<div className='cont_box'>
											<div className='img_box'>
												<img src={logoPath4} alt='logo' />
											</div>
											<p>
												자동차 차체 용접기 (저항용접기) 제작 전문 업체로써, 품목별로 개별적인 생산공정
												<br className='pc_ver' /> 관리 구현
											</p>
										</div>
										<div className='info_desc'>
											<ul>
												<li>
													<span>1)</span> <p>생산관리 프로세스 재정립 및 시스템 고도화</p>
												</li>
												<li>
													<span>2)</span> <p>임가공 프로세스 정립 및 재고관리 방안 수립</p>
												</li>
												<li>
													<span>3)</span> <p>시스템 구동 퍼포먼스 개선 (Database 튜닝, 2배속 이상 증가)</p>
												</li>
											</ul>
										</div>
									</div>
									<div className='partners_desc'>
										<div className='cont_box'>
											<div className='img_box'>
												<img src={logoPath5} alt='logo' />
											</div>
											<p>방산 부품 생산 업체로서, 프로젝트별 재고 관리에 주안점</p>
										</div>
										<div className='info_desc'>
											<ul>
												<li>
													<span>1)</span> <p>프로젝트별 판매 및 재고관리 시스템 구축</p>
												</li>
												<li>
													<span>2)</span> <p>현업부서의 실정을 고려한 심플하고 유연한 생산관리 시스템 구축</p>
												</li>
												<li>
													<span>3)</span> <p>품목별 품질 검사 유형 관리 및 검사실적 관리</p>
												</li>
											</ul>
										</div>
									</div>
									<div className='partners_desc'>
										<div className='cont_box'>
											<img src={logoPath6} alt='logo' className='img_size' />
											<img src={logoPath12} alt='logo' />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='product_cont_all'>
							<div className='product_cont'>
								<p className='title'>건축 자재</p>
								<p className='title_desc'>
									일련의 자재 관련 업무들을 통합 운영함으로써 계획성 있고 효율적인 자재 조달이 가능합니다. <br /> 또한 세부적인 소요 계획 관리를 통해 원가 절감 효과를 얻을 수 있고 나아가 자재 구매
									계획을 체계적으로 관리함으로써 구매 경쟁력을 강화할 수 있습니다.
								</p>
								<div className='partners'>
									<div className='partners_desc'>
										<div className='cont_box'>
											<div className='img_box'>
												<img src={logoPath8} alt='logo' />
											</div>
											<p>
												건축자재 제조업체, 기성 매출에 따른 건축현장별 재고 수불 관리 및 생산 Lot 별<br className='pc_ver' /> 원가 / 손익 계산
											</p>
										</div>
										<div className='info_desc'>
											<ul>
												<li>
													<span>1)</span> <p>고객 주문을 기준으로 제품 규격별 작업지시 생성 자동화</p>
												</li>
												<li>
													<span>2)</span> <p>운송차량 적재 한도를 고려한 배치 실행계획 자동 생성</p>
												</li>
												<li>
													<span>3)</span> <p>운송차량별 운송비 관리</p>
												</li>
												<li>
													<span>4)</span> <p>생산설비별 합리적인 간접비 배부 체계</p>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='product_cont_all'>
							<div className='product_cont'>
								<p className='title'>식품 및 음료 </p>
								<p className='title_desc'>생산품 LOT 관리를 통해 제품별 유효 기간 관리, 악성재고 식별 등을 파악하여 효율적인 제품 수명 주기 관리가 가능합니다. </p>
								<div className='partners'>
									<div className='partners_desc'>
										<div className='cont_box'>
											<div className='img_box'>
												<img src={logoPath9} alt='logo' />
											</div>
											<p>도축, 육가공 제조 및 유통을 주된 사업으로 하는 축산 양돈 전문 기업으로, 도축 및 육가공 업종별 특화 모듈 구현</p>
										</div>
										<div className='info_desc'>
											<ul>
												<li>
													<span>1)</span> <p>돈지육 매입 정산 관리 - 개체별 지급액 관리</p>
												</li>
												<li>
													<span>2)</span> <p>저울 시스템과 연계한 업종 특화 생산실적 관리 - 묶음 번호, 이력번호, 부산물 관리</p>
												</li>
												<li>
													<span>3)</span> <p>바코드 (PDA)를 이용한 물류 및 재고관리</p>
												</li>
												<li>
													<span>4)</span> <p>축산물 이력제 신고시스템 연계 (국내 최초)</p>
												</li>
												<li>
													<span>5)</span> <p>부분육 정산서 (부위별 손익 리포트)</p>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='product_cont_all'>
							<div className='product_cont'>
								<p className='title'>사출 성형  </p>
								<p className='title_desc'>생산을 위한 원료의 투입 중량 계산, 스크랩 재고의 분쇄 및 재사용, 설비별 제조 원가 계산 등이 가능하도록 사출 성형 업종의 특수성을 반영하여 구현됩니다. </p>
								<div className='partners'>
									<div className='partners_desc'>
										<div className='cont_box'>
											<div className='img_box'>
												<img src={logoPath} alt='logo' />
											</div>
											<p>
												하나의 SAP Business one 시스템 안에서 업종이 전혀 다른 3개의 사업 부문
												<br className='pc_ver' />
												(자동차 부품 제조 / 플라스틱 용기 제조 / 윤활유 유통)을 구축
											</p>
										</div>
										<div className='info_desc'>
											<ul>
												<li>
													<span>1)</span> <p>스크랩 발생 및 재사용 관리</p>
												</li>
												<li>
													<span>2)</span> <p>BOM 기준으로 일치할 수 없는 실제 원료 투입에 대한 재고 관리</p>
												</li>
												<li>
													<span>3)</span> <p>물류 이동 시 제약조건 (적재 한도 등)을 고려한 시스템 설계</p>
												</li>
												<li>
													<span>4)</span> <p>물류비 관리</p>
												</li>
											</ul>
										</div>
									</div>
									{/* <div className='partners_desc'>
										<div className='cont_box'>
											<div className='img_box'>
												<img src={logoPath13} alt='logo' className='img_size' />
											</div>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='myScroll' id='distribution'>
					<h2>[유통]</h2>
					<p className='title_desc'>
						종합적이며 다양한 관점에서 신뢰성 있는 정보를 적시에 제공함으로써 다각도에서의 수익성 분석을 가능하게 합니다. <br />
						이를 바탕으로 정확한 손익 추정과 분석을 통해 의사 결정권자가 시장 변화에 따른 합리적인 의사 결정을 할 수 있도록 지원합니다.
					</p>
					<div className='cont_2'>
						<div className='product_cont_all'>
							<div className='product_cont'>
								<p className='title'>자동차 부품 </p>
								<p className='title_desc'>
									BOM 관리, 생산성 향상 및 타이트한 납기 관리를 통해 적시적량의 부품 공급이 가능합니다. <br />
									이를 바탕으로 완성 차의 5 STAR, SQ 인증 등에 대응하여 협력사 인증 평가에서 우수한 성과를 기대할 수 있습니다.
								</p>
								<div className='partners'>
									<div className='partners_desc'>
										<div className='cont_box'>
											<div className='img_box'>
												<img src={logoPath} alt='logo' />
											</div>
											<p>
												하나의 SAP Business one 시스템 안에서 업종이 전혀 다른 3개의 사업 부문
												<br className='pc_ver' />
												(자동차 부품 제조 / 플라스틱 용기 제조 / 윤활유 유통)을 구축
											</p>
										</div>
										<div className='info_desc'>
											<ul>
												<li>
													<span>1)</span> <p>모비스 SPIN 시스템과 연계한 업무 프로세스 수립</p>
												</li>
												<li>
													<span>2)</span> <p>SPIN 주문별 제조사 (정유사) 자동 분배 시스템 구현</p>
												</li>
												<li>
													<span>3)</span> <p>제조사 (정유사) 소분 관리 프로세스 수립</p>
												</li>
												<li>
													<span>4)</span> <p>제조사 용기 재고 예측 관리</p>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='product_cont_all'>
							<div className='product_cont'>
								<p className='title'>장비 및 부품  </p>
								<p className='title_desc'>
									생산 공정을 세부적으로 관리하여 시스템화 함으로써 정확성 있는 생산 실적 정보를 얻을 수 있습니다. <br />
									그리고 실시간 재고 추적을 통해 재고 흐름을 빠르게 파악하여 적정 재고 수준을 최적화 할 수 있습니다.
								</p>
								<div className='partners'>
									<div className='partners_desc'>
										<div className='cont_box'>
											<div className='img_box usa'>
												<img src={logoPath10} alt='logo' className='img_size' />
											</div>
											<p>
												한화 솔루션 재생에너지 사업 부문 미국 유통 법인으로, 판매 / 구매 프로세스의 시스템
												<br className='mo_ver' /> 재구축 진행
											</p>
										</div>
										<div className='info_desc'>
											<ul>
												<li>
													<span>1)</span> <p>판매 유형 (CIP / SIP / Consignment / DDP / DropShip)별 출하, 재고관리 프로세스의 다양화</p>
												</li>
												<li>
													<span>2)</span> <p>SalesBOM 구성 및 구성 품목 재고 관리</p>
												</li>
												<li>
													<span>3)</span> <p>ATP 관리</p>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='product_cont_all'>
							<div className='product_cont'>
								<p className='title'>식품 및 음료</p>
								<p className='title_desc'>생산품 LOT 관리를 통해 별 유효 기간 관리, 악성재고 식별 등을 파악하여 효율적인 제품 수명 주기 관리가 가능합니다. </p>
								<div className='partners'>
									<div className='partners_desc'>
										<div className='cont_box'>
											<div className='img_box'>
												<img src={logoPath15} alt='logo' className='img_size' />
												<img src={logoPath16} alt='logo' className='img_size_2' />
												<img src={logoPath17} alt='logo' className='img_size_2' />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='product_cont_all'>
							<div className='product_cont'>
								<p className='title'>철강  </p>
								<p className='title_desc'>
									중량 단위로 거래되는 품목의 특수성을 고려하여 수량 및 중량 수불이 가능하도록 구현되며, KSD 표준 규격 제품 외에도 고객사 개별 오더의 전반적인 관리가 가능합니다.  
								</p>
								<div className='partners'>
									<div className='partners_desc'>
										<div className='cont_box'>
											<div className='img_box'>
												<img src={logoPath18} alt='logo' className='img_size' />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='product_cont_all'>
							<div className='product_cont'>
								<p className='title'>기타 </p>
								<div className='partners'>
									<div className='partners_desc'>
										<div className='cont_box'>
											<div className='img_box'>
												<img src={logoPath19} alt='logo' />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='myScroll n3' id='etc'>
					<h2>[기타]</h2>
					<div className='con_3'>
						<p className='title_desc'>
							현 디지털 시대에서 비즈니스는 비대면 플랫폼 형태로 이루어지는 경향이 점차 강해지고 있습니다.
							<br />
							ERP 시스템을 통해 구현된 최적화 업무 프로세스를 바탕으로 급변하는 시장 동향과 기술 혁신에 신속 대응하고, 이를 바탕으로 산업 내 경쟁 우위를 확보하여 경영 혁신 효과까지 기대할 수 있습니다.
						</p>
						<div className='product_cont_all'>
							<div className='product_cont'>
								<div className='partners'>
									<div className='partners_desc'>
										<div className='cont_box'>
											<div className='img_box'>
												<img src={logoPath20} alt='logo' className='img_size_2' />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}
