import Layout from '../../common/Layout/Layout';
import './Eis.scss';
import useFetchJsonData from '../../../hooks/useFetchJsonData';

export default function Eis() {
	const breadcrumbs = [{ title: 'service' }, { title: 'Eis' }];
	const { data, loading, error } = useFetchJsonData('/DB/eisEffectsData.json');
	// 데이터 로딩 중
	if (loading) {
		return <div>Loading...</div>;
	}

	// 데이터 로딩 에러
	if (error) {
		return <div>Error: {error.message}</div>;
	}
	return (
		<Layout className='eis erp' title='EIS' breadcrumbs={breadcrumbs}>
			<div className='erp_cont'>
				<h2 className='title'>EIS 소개</h2>
				<p>
					<span className='main_c'>EIS (Executive Information System)</span> 란 조직의 임원들이 성공적인 경영을 위해 필요로 하는 조직 내외부 정보를 효과적으로 제공하기 위한 정보 시스템을 의미합니다.
					<br />
					즉, 최종 경영진이 데이터 기반의 신속하고 정확한 의사 결정 및 대외 보고 등을 할 수 있도록 다양한 데이터를 제공합니다.
				</p>
			</div>
			<div className='erp_cont'>
				<h2 className='title'>EIS 효율성</h2>
				<div className='erp_cont_desc'>
					<div className='txt_desc'>
						<p>
							<strong>경영진의 효과적인 의사결정 지원이 가능합니다.</strong>
							경영진의 효과적인 의사결정 지원은 정확한 데이터와 분석 도구를 활용하여 객관적인 정보를 제공하고, 조직 내 투명한 커뮤니케이션을 통해 모든 구성원이 명확한 목표를 이해하고 추구하도록 하는데
							초점을 맞춥니다. 이를 통해 기업은 지속 가능한 성장을 달성하고, 변화하는 시장 환경에서 경쟁 우위를 확보할 수 있습니다.
						</p>
						<p>
							<strong>새로운 비즈니스 모델을 창출할 수 있습니다.</strong>
							새로운 비즈니스 모델 창출은 혁신적인 가치 제안, 고객 관계 및 수익 창출 메커니즘을 통해 시장의 미충족 수요를 만족시키고 경쟁에서 독특한 위치를 확보하는 과정입니다. 이 과정은 기술 발전,
							고객 행동 변화, 그리고 지속 가능한 경영 전략을 기반으로 합니다.
						</p>
						<p>
							<strong>기업 간의 경쟁에서 우위 선점 기반을 제공합니다.</strong>
							기업 간 경쟁에서 우위를 선점하는 것은 혁신, 고품질의 제품과 서비스, 그리고 효율적인 운영 전략을 통해 시장에서 독보적인 위치를 확립하는 과정입니다. 이는 고객 만족도를 높이고, 브랜드
							가치를 강화하여 장기적인 비즈니스 성공을 보장합니다.
						</p>
					</div>
					<div className='img_desc'></div>
				</div>
			</div>
			<div className='erp_cont'>
				<h2 className='title'>EIS 기대 효과</h2>
				<div className='info_box_all'>
					{data?.eisEffects.map((effect, index) => (
						<div className='info_box' key={index}>
							<h3>{effect.title}</h3>
							<p>{effect.description}</p>
						</div>
					))}
				</div>
			</div>
		</Layout>
	);
}
