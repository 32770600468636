import { useRef, useState } from 'react';

export const useDebounce = (value) => {
	const [Debouncedval, setDebouncedval] = useState(value);
	const eventBlocker = useRef(null);

	clearTimeout(eventBlocker.current);

	eventBlocker.current = setTimeout(() => {
		setDebouncedval(value);
	}, 500);

	return Debouncedval;
};
