import Layout from '../../common/Layout/Layout';
import './Ito.scss';
import useFetchJsonData from '../../../hooks/useFetchJsonData';

export default function Ito() {
	const breadcrumbs = [{ title: 'service' }, { title: 'ito' }];
	const { data, loading, error } = useFetchJsonData('/DB/itoEffectsData.json');
	// 데이터 로딩 중
	if (loading) {
		return <div>Loading...</div>;
	}

	// 데이터 로딩 에러
	if (error) {
		return <div>Error: {error.message}</div>;
	}
	return (
		<Layout className='erp ito' title='ITO' breadcrumbs={breadcrumbs}>
			<div className='erp_cont'>
				<h2 className='title'>ITO 소개</h2>
				<p>
					<span className='main_c'>ITO (IT Outsourcing)</span> 란 기업의 정보 시스템과 관련된 업무를 외부 전문가에게 위임하여 운영하는 방식을 말합니다. 기획부터 유지보수에 이르기까지 외부 전문 기관에
					업무를 위탁함으로써, 빠르게 변화하는 비즈니스 환경 속에서 시스템 환경을 최적화하고 효과적인 미래 시스템 운영을 가능하게 합니다. 즉, 고객의 비즈니스 상황에 맞는 아웃소싱 모델을 제공하여 높은
					고객 만족도를 달성하고, 동시에 경쟁력을 강화할 수 있습니다.
				</p>
			</div>
			<div className='erp_cont'>
				<h2 className='title'>ITO 효율성</h2>
				<div className='erp_cont_desc'>
					<div className='txt_desc'>
						<p>
							<strong>기업의 비용 구조를 낮출 수 있습니다.</strong> 전문 업체를 통한 프로세스 최적화를 통해 인프라, 소프트웨어 라이선스 및 인력에 대한 비용을 절감할 수 있습니다.
						</p>
						<p>
							<strong>유연한 IT 관리가 가능합니다.</strong> 기업의 내부 비즈니스 상황을 고려하여 성수기와 비수기에 맞춰 서비스 운영을 확대하거나 축소함으로써 탄력적인 운영이 가능합니다.
						</p>
						<p>
							<strong>비즈니스 우선순위에 집중할 수 있습니다.</strong> 시간과 자원을 확보함으로써 중요한 사항에 집중할 수 있으며, 이는 성과 향상 및 수익성 증대 등 비즈니스 성장을 촉진할 수 있습니다.
						</p>
					</div>
					<div className='img_desc'></div>
				</div>
			</div>
			<div className='erp_cont'>
				<h2 className='title'>ITO 기대 효과</h2>
				<div className='info_box_all'>
					{data?.itoEffects.map((effect, index) => (
						<div className='info_box' key={index}>
							<h3>{effect.title}</h3>
							<p>{effect.description}</p>
						</div>
					))}
				</div>
			</div>
		</Layout>
	);
}
