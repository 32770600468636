import { useDebounce } from '../../../hooks/useDebounce';
import Layout from '../../common/Layout/Layout';
import './Contact.scss';
import { useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
// import { useNavigate } from 'react-router-dom';

export default function Contact() {
	const breadcrumbs = [{ title: 'contact' }, { title: 'contact' }];
	const form = useRef(null);
	const initVal = useRef({
		userName: '',
		email: '',
		pwd1: '',
		pwd2: '',
		edu: '',
		gender: '',
		interest: [],
		comments: '',
	});
	const [Val, setVal] = useState(initVal.current);
	const [Errs, setErrs] = useState({});
	const DebouncedVal = useDebounce(Val);

	// 전화번호 포매팅 함수
	function formatPhoneNumber(phoneNumber, previousNumber = '') {
		const numbersOnly = phoneNumber.replace(/\D/g, '');
		let formattedNumber = numbersOnly;

		// 삭제 중에 마지막 구분자를 제거
		if (previousNumber.length > phoneNumber.length && previousNumber.endsWith('-')) {
			return previousNumber.slice(0, -1); // 마지막 '-' 제거
		}

		if (numbersOnly.startsWith('02')) {
			// 서울 지역번호
			if (numbersOnly.length <= 2) {
				formattedNumber = numbersOnly;
			} else if (numbersOnly.length <= 6) {
				formattedNumber = `${numbersOnly.slice(0, 2)}-${numbersOnly.slice(2)}`;
			} else {
				// 중간 번호를 3자리 또는 4자리로 결정
				const midLength = numbersOnly.length === 9 ? 3 : 4;
				formattedNumber = `${numbersOnly.slice(0, 2)}-${numbersOnly.slice(2, 2 + midLength)}-${numbersOnly.slice(2 + midLength)}`;
			}
		} else if (/^0[3-9][1-9]/.test(numbersOnly)) {
			// 3자리 지역번호
			if (numbersOnly.length <= 3) {
				formattedNumber = numbersOnly;
			} else if (numbersOnly.length <= 6) {
				formattedNumber = `${numbersOnly.slice(0, 3)}-${numbersOnly.slice(3)}`;
			} else {
				// 전체 길이를 기반으로 중간 번호의 길이를 결정
				const midLength = (numbersOnly.length === 10) ? 3 : (numbersOnly.length - 7);
				formattedNumber = `${numbersOnly.slice(0, 3)}-${numbersOnly.slice(3, 3 + midLength)}-${numbersOnly.slice(3 + midLength)}`;
			}
		}
		else {
			// 휴대전화 및 기타 지역번호
			if (numbersOnly.length <= 3) {
				formattedNumber = numbersOnly;
			} else if (numbersOnly.length === 10) {
				formattedNumber = `${numbersOnly.slice(0, 3)}-${numbersOnly.slice(3, 6)}-${numbersOnly.slice(6)}`;
			} else if (numbersOnly.length === 11) {
				formattedNumber = `${numbersOnly.slice(0, 3)}-${numbersOnly.slice(3, 7)}-${numbersOnly.slice(7)}`;
			} else {
				formattedNumber = numbersOnly; // 처리할 수 없는 번호는 그대로 반환
			}
		}

		return formattedNumber;
	}

	// handleChange 함수 수정
	const handleChange = (e) => {
		const { name, value } = e.target;

		if (name === 'email' || name === 'userHomepage') {
			// 한글 및 불필요한 문자를 제거하는 로직
			const cleanedValue = value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '');
			setVal({ ...Val, [name]: cleanedValue });
		} else if (name === 'userNumber') {
			// 전화번호 필드에 대한 처리, 이전 값과 함께 formatPhoneNumber 호출
			const formattedNumber = formatPhoneNumber(value, Val.userNumber);
			setVal({ ...Val, [name]: formattedNumber });
		} else {
			// 다른 모든 입력 필드에 대한 처리
			setVal({ ...Val, [name]: value });
		}
	};

	const check = (value) => {
		const errs = {};
		//text 인증로직
		if (value.userName.length < 1) {
			errs.userName = '이름을 입력해주세요.';
		}
		if (!value.userCompany || value.userCompany.length < 1) {
			errs.userCompany = '회사이름을 입력해주세요.';
		}
		// if (!value.userSector || value.userSector.length < 1) {
		// 	errs.userSector = '업종을 입력해주세요.';
		// }
		if (!value.userNumber) {
			errs.userNumber = '전화번호를 입력해주세요.';
		} else if (value.userNumber.replace(/\D/g, '').length < 8) {
			errs.userNumber = '전화번호를 8자리 이상 입력하세요.';
		}

		//textarea 인증로직
		if (value.comments.length < 10) {
			errs.comments = '남기는 말은 최소 10글자 이상 입력하세요.';
		}
		//email형식 인증로직
		if (!value.email || !/@/.test(value.email)) {
			errs.email = '이메일주소에는 @를 포함해야 합니다.';
		} else {
			const [forward, backward] = value.email.split('@');
			if (!forward || !backward) {
				errs.email = '@앞뒤로 문자값이 있어야 합니다.';
			} else {
				const [forward, backward] = value.email.split('.');
				if (!forward || !backward) {
					errs.email = '이메일 .앞뒤로 문자값이 있어야 합니다.';
				}
			}
		}

		return errs;
	};

	const sendEmail = (e) => {
		e.preventDefault();

		// 필수 입력 필드 검사
		const isEmptyRequiredField = Array.from(form.current.querySelectorAll('td:has(em) + td > input, td:has(em) + td > textarea')).some((field) => !field.value.trim());
		if (isEmptyRequiredField) {
			alert('필수 입력 사항을 모두 채워주세요.');
			return; // 함수 실행 중단
		}

		// 전화번호 유효성 검사
		const phoneRegex = /^(\d{2,3})-(\d{3,4})-(\d{3,4})$/;
		if (!phoneRegex.test(Val.userNumber)) {
			alert('유효한 전화번호를 입력해주세요.');
			return; // 함수 실행 중단
		}

		// 이메일 주소가 입력되었고, 유효하지 않을 경우의 검사
		// if (Val.email && !Val.email.includes('@')) {
		// 	alert('유효한 이메일 주소를 입력해주세요.');
		// 	return; // 함수 실행 중단
		// }

		// 이메일 주소 유효성 검사
		if (Val.email) {
			const atPos = Val.email.indexOf('@');
			if (atPos === -1) {
				alert('유효한 이메일 주소를 입력해주세요. (@가 포함되어야 합니다.)');
				return; // 함수 실행 중단
			}
			const dotPos = Val.email.indexOf('.', atPos + 1);
			if (dotPos === -1) {
				alert('유효한 이메일 주소를 입력해주세요. (도메인에는 .이 포함되어야 합니다.)');
				return; // 함수 실행 중단
			}
			if (atPos === Val.email.length - 1 || dotPos === Val.email.length - 1) {
				alert('유효한 이메일 주소를 입력해주세요. (도메인의 마지막에는 문자가 와야 합니다.)');
				return; // 함수 실행 중단
			}
		}

		let currentErrs = check(Val);
		if (!Val.email) {
			delete currentErrs.email;
		}

		// 남기는 말의 길이 검사
		if (Val.comments.length < 10) {
			alert('남기는 말은 최소 10글자 이상 입력하세요.');
			return; // 함수 실행 중단
		}

		// 개인정보 취급방침 동의 체크
		const isTermsChecked = form.current.querySelector('#terms_check').checked;
		if (!isTermsChecked) {
			alert('개인정보 취급방침에 동의해주세요.');
			return; // 함수 실행 중단
		}

		// emailjs를 사용한 이메일 전송 로직
		emailjs.sendForm('service_pje8g0s', 'template_euxnnxe', form.current, 'hFTHSSj3Lhhp1jjfy').then(
			(result) => {
				alert('문의내용이 성공적으로 전달되었습니다.');
				form.current.reset(); // 폼 초기화
				setVal(initVal.current); // 상태 초기화
			},
			(error) => {
				alert('문의내용 전송에 실패했습니다.');
				console.error(error);
			}
		);
	};

	useEffect(() => {
		setErrs(check(DebouncedVal));
	}, [DebouncedVal]);

	return (
		<Layout className='contact' title='CONTACT' breadcrumbs={breadcrumbs}>
			<p className='tit_desc'>
				엑스원패스트(주)와의 만남이 좋은 인연이 되도록 노력하겠습니다. <br className='mo_ver' />
				궁금하신 사항은 아래 문의 남겨주시면 빠른 시일 내 연락드리겠습니다.
			</p>
			<form onSubmit={sendEmail} ref={form}>
				<fieldset>
					<legend className='dn'>회원가입 폼</legend>
					<table>
						<tbody>
							<tr>
								<td>
									성명<em></em>
								</td>
								<td>
									<input type='text' name='userName' value={Val.userName} onChange={handleChange} />
									{Errs.userName && <p className='err_txt'>{Errs.userName}</p>}
								</td>
							</tr>
							<tr>
								<td>
									회사명<em></em>
								</td>
								<td>
									<input type='text' name='userCompany' value={Val.userCompany} onChange={handleChange} />
									{Errs.userCompany && <p className='err_txt'>{Errs.userCompany}</p>}
								</td>
							</tr>
							<tr>
								<td>업종</td>
								<td>
									<input type='text' name='userSector' value={Val.userSector} onChange={handleChange} />
									{Errs.userSector && <p className='err_txt'>{Errs.userSector}</p>}
								</td>
							</tr>
							<tr>
								<td>홈페이지</td>
								<td>
									<input type='text' name='userHomepage' value={Val.userHomepage} onChange={handleChange} />
									{Errs.userHomepage && <p className='err_txt'>{Errs.userHomepage}</p>}
								</td>
							</tr>
							<tr>
								<td>
									연락처<em></em>
								</td>
								<td>
									<input type='text' name='userNumber' value={Val.userNumber} onChange={handleChange} />
									{Errs.userNumber && <p className='err_txt'>{Errs.userNumber}</p>}
								</td>
							</tr>
							<tr>
								<td>이메일</td>
								<td>
									<input type='text' name='email' value={Val.email} onChange={handleChange} />
									{Errs.email && <p className='err_txt'>{Errs.email}</p>}
								</td>
							</tr>
							<tr>
								<td>
									문의사항<em></em>
								</td>
								<td>
									<textarea name='comments' cols='30' rows='5' value={Val.comments} onChange={handleChange}></textarea>
									{Errs.comments && <p className='err_txt'>{Errs.comments}</p>}
								</td>
							</tr>
							<tr className='terms_cont'>
								<td colSpan='2'>
									<input type='checkbox' id='terms_check' />
									<label htmlFor='terms_check'>
										<span>[필수] 개인정보 취급방침에 동의합니다.</span>
									</label>
								</td>
							</tr>
							<tr className='terms_cont dn'>
								<td colSpan='2'>
									<input type='checkbox' id='terms_check' />
									<label htmlFor='terms_check'>
										<span>[필수] 개인정보 취급방침에 동의합니다.</span>
									</label>
									<div className='terms_box'>
										<p>
											개인정보 취급방침
											<br />
											<br />
											1. 수집하는 개인정보 항목 <br />
											엑스원패스트(주) (이하 "회사"라 함)는 EXONEFAST ERP 상담 신청 등을 위해 아래와 같은 개인정보를 수집하고 있습니다. <br />- 수집항목 : 성명, 회사명, 업종, 홈페이지, 연락처, 이메일
											<br />
											- 개인정보 수집방법 : 홈페이지 (상담 신청) <br />
											<br />
											2. 개인정보의 수집 및 이용목적 회사는 EXONEFAST ERP 상담신청에 대한 상담을 위해 수집한 개인정보를 활용합니다.
											<br /> <br />
											3. 개인정보의 보유 및 이용기간 회사는 개인정보 수집 및 이용목적이 달성된 후에는 예외 없이 해당 정보를 지체 없이 파기합니다.
											<br />
											<br /> 4. 수집한 개인정보의 위탁 회사는 고객의 개인정보를 제3자에게 제공하고 있지 않습니다. 다만 회사가 고객으로부터 사전적으로 개별 동의를 받은 경우, 법령의 규정에 의거하거나,
											수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우에는 고객의 개인정보를 제3자에게 제공할 수 있습니다. <br />
											<br />
											개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항 <br />
											쿠키 등 인터넷 서비스 이용시 자동 생성되는 개인정보를 수집하는 장치를 운영하지 않습니다. 개인정보에 관한 민원서비스 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래
											기관에 문의하시기 바랍니다.
											<br />
											<br /> 개인정보분쟁조정위원회 ( http://kopico.or.kr / 02-405-5150) <br />
											정보보호마크인증위원회 (http://www.eprivacy.or.kr / 02-580-0533~4) <br />
											경찰청 사이버안전국 (http://cyberbureau.police.go.kr / 182) <br />
											경찰청 사이버테러대응센터 (http://www.ctrc.go.kr / 02-392-0330)
										</p>
									</div>
								</td>
							</tr>
							<tr>
								<td colSpan='2'>
									<div className='sub_cont'>
										{/* <input type='reset' value='취소하기' /> */}
										<input type='submit' value='전송하기' />
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</fieldset>
			</form>
		</Layout>
	);
}
