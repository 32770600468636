import React, { useState, useEffect } from 'react';
import MenuButton from './MenuButton';
import './Header.scss';
import Gnb from './Gnb';
import GnbMobile from './GnbMobile';
import { Link } from 'react-router-dom';
import ScrollToTopButton from './ScrollToTopButton';

export default function Header() {
	const logoPath = `${process.env.PUBLIC_URL}/img/logo.png`;
	const [gnbMoActive, setGnbMoActive] = useState(false);

	const toggleGnbMo = () => {
		setGnbMoActive(!gnbMoActive);
	};

	const [hasScrolled, setHasScrolled] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const isScrolled = window.scrollY > 0;
			setHasScrolled(isScrolled);
		};

		window.addEventListener('scroll', handleScroll);

		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<>
			<header className={hasScrolled ? 'bg_change' : ''}>
				<div className='inner'>
					<h1 className='logo'>
						<Link to='/'>
							<img src={logoPath} alt='logo' />
						</Link>
					</h1>
					<Gnb />
					<GnbMobile isActive={gnbMoActive} setIsActive={setGnbMoActive} />
					<MenuButton isActive={gnbMoActive} toggleButton={toggleGnbMo} />
				</div>
			</header>

			<ScrollToTopButton />
		</>
	);
}
