import React, { useState } from 'react';

function ScrollToTopButton() {
	const [isVisible, setIsVisible] = useState(false);

	window.onscroll = () => {
		if (window.pageYOffset > 300) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return (
		<div className={`top_btn ${isVisible ? 'visible' : 'hidden'}`} onClick={scrollToTop}>
			TOP
		</div>
	);
}

export default ScrollToTopButton;
