import { useEffect, useState, useCallback } from "react";

const useScrollPosition = () => {
    const [posArr, setPosArr] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [isTabContFixed, setIsTabContFixed] = useState(false);
    const [isContentDescOn, setIsContentDescOn] = useState(false);

    const calculatePositions = useCallback(() => {
        const headerH = document.querySelector("header")?.clientHeight || 0;
        const tabContH = document.querySelector(".tab_cont")?.clientHeight || 0;
        const positions = Array.from(document.querySelectorAll(".myScroll")).map(
            (el) =>
                el.getBoundingClientRect().top +
                window.pageYOffset -
                headerH -
                tabContH
        );
        setPosArr(positions);

        // console.log(positions)
    }, []);

    useEffect(() => {
        // 페이지 로드 및 리사이즈 이벤트에 대응하여 위치 계산
        calculatePositions();
        window.addEventListener("load", calculatePositions);
        window.addEventListener("resize", calculatePositions);

        return () => {
            window.removeEventListener("load", calculatePositions);
            window.removeEventListener("resize", calculatePositions);
        };
    }, [calculatePositions]);

    const handleScrollForFixedClass = useCallback(() => {
        const contentElement = document.querySelector(".manufacturing .content");
        const tabContElement = document.querySelector(".tab_cont");
        const contentDescElement = document.querySelector(".content_desc");

        if (!contentElement || !tabContElement || !contentDescElement) return;

        const trigger = contentElement.getBoundingClientRect().top + window.pageYOffset;
        let triggerPoint = trigger * 1.05;

        if (window.innerWidth <= 500) {
            triggerPoint = trigger - 9.75;
        }

        setIsTabContFixed(window.pageYOffset >= triggerPoint);
        setIsContentDescOn(window.pageYOffset >= triggerPoint);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            requestAnimationFrame(() => {
                const scrollPosition = window.pageYOffset;
                // console.log(scrollPosition)

                const currentIndex = posArr.findIndex(
                    (pos, index) =>
                        scrollPosition >= pos &&
                        (index === posArr.length - 1 || scrollPosition < posArr[index + 1])
                );

                if (currentIndex !== -1 && currentIndex !== activeTab) {
                    setActiveTab(currentIndex);
                }
                handleScrollForFixedClass();
            });
        };

        window.addEventListener("scroll", handleScroll);
        // 첫 페이지 로드 시에만 스크롤 위치 확인
        handleScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };


    }, [posArr, activeTab, handleScrollForFixedClass]);

    // const scrollToSection = useCallback((index) => {
    //     // setActiveTab(index);
    //     const myScrollElements = document.querySelectorAll(".myScroll");

    //     if (myScrollElements && myScrollElements[index]) {
    //         const header = document.querySelector("header");
    //         const tabCont = document.querySelector(".tab_cont");
            
    //         if (header && tabCont) {
    //             const headerH = header.clientHeight;
    //             const tabContH = tabCont.clientHeight;
    //             const targetPosition =
    //                 myScrollElements[index].getBoundingClientRect().top +
    //                 window.pageYOffset -
    //                 headerH -
    //                 tabContH;

    //             window.scrollTo({
    //                 top: targetPosition,
    //                 behavior: "smooth",
    //             });
    //             console.log(targetPosition)
    //         }
    //     } else {
    //         console.warn("Attempted to scroll to an undefined element."); // 오류 로깅
    //     }

    // }, []);

    return { activeTab, isTabContFixed, isContentDescOn};
};

export default useScrollPosition;