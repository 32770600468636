import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
// import { useNavigate, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Gnb() {
	const [activeLiIndex, setActiveLiIndex] = useState(null);
	const [isGnbHovered, setIsGnbHovered] = useState(false);

	const handleMouseEnterLi = (index) => {
		setActiveLiIndex(index);
	};

	const handleMouseLeaveLi = () => {
		if (!isGnbHovered) {
			setActiveLiIndex(null);
		}
	};

	const handleMouseEnterGnb = () => {
		setIsGnbHovered(true);
	};

	const handleMouseLeaveGnb = () => {
		setIsGnbHovered(false);
		if (!isGnbHovered) {
			setActiveLiIndex(null);
		}
	};

	// Adjust the scroll behavior
	const customScroll = (el) => {
		setTimeout(() => {
			// 비동기 콘텐츠가 로드될 시간을 주기 위해 setTimeout 사용
			const headerHeight = document.querySelector('header')?.clientHeight || 0;
			const yOffset = -headerHeight; // 필요한 경우 추가적인 오프셋을 적용할 수 있습니다.
			const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

			window.scrollTo({ top: y, behavior: 'smooth' });
		}, 700); // 300ms 대기. 실제 로딩 시간에 따라 조절이 필요할 수 있습니다.
	};

	return (
		<div className={`gnb ${isGnbHovered ? 'on' : ''}`} onMouseEnter={handleMouseEnterGnb} onMouseLeave={handleMouseLeaveGnb}>
			<nav>
				<ul className='depth1'>
					<li className={activeLiIndex === 0 ? 'on' : ''} onMouseEnter={() => handleMouseEnterLi(0)} onMouseLeave={handleMouseLeaveLi}>
						<NavLink to='/about'>COMPANY</NavLink>
						<div className='depth2'>
							<ul>
								<li>
									<NavLink to='/about'>About us</NavLink>
								</li>
								<li>
									<NavLink to='/ceo'>CEO Message</NavLink>
								</li>
								<li>
									<NavLink to='/mission'>Mission / Vision</NavLink>
								</li>
								<li>
									<NavLink to='/location'>Location</NavLink>
								</li>
							</ul>
						</div>
					</li>
					<li className={activeLiIndex === 1 ? 'on' : ''} onMouseEnter={() => handleMouseEnterLi(1)} onMouseLeave={handleMouseLeaveLi}>
						<NavLink to='/erp'>SERVICE</NavLink>
						<div className='depth2'>
							<ul>
								<li>
									<NavLink to='/erp'>ERP</NavLink>
								</li>
								<li>
									<NavLink to='/eis'>EIS</NavLink>
								</li>
								<li>
									<NavLink to='/scm'>SCM</NavLink>
								</li>
								<li>
									<NavLink to='/ito'>ITO</NavLink>
								</li>
							</ul>
						</div>
					</li>
					<li className={activeLiIndex === 2 ? 'on' : ''} onMouseEnter={() => handleMouseEnterLi(2)} onMouseLeave={handleMouseLeaveLi}>
						<NavLink to='/manufacturing'>REFERENCE</NavLink>
						<div className='depth2'>
							<ul>
								<li>
									<HashLink to='/manufacturing#manufacturing' scroll={customScroll}>
										Manufacturing
									</HashLink>
								</li>
								<li>
									<HashLink to='/manufacturing#distribution' scroll={customScroll}>
										Distribution
									</HashLink>
								</li>
								<li>
									<HashLink to='/manufacturing#etc' scroll={customScroll}>
										ETC
									</HashLink>
								</li>
							</ul>
						</div>
					</li>
					<li className={activeLiIndex === 3 ? 'on' : ''} onMouseEnter={() => handleMouseEnterLi(3)} onMouseLeave={handleMouseLeaveLi}>
						<NavLink to='/contact'>CONTACT</NavLink>
					</li>
				</ul>
			</nav>
		</div>
	);
}

export default Gnb;