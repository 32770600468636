import Layout from '../../common/Layout/Layout';
import './Erp.scss';
import useFetchJsonData from '../../../hooks/useFetchJsonData';

export default function Erp() {
	const breadcrumbs = [{ title: 'service' }, { title: 'ERP' }];
	const { data, loading, error } = useFetchJsonData('/DB/erpEffectsData.json');
	// 데이터 로딩 중
	if (loading) {
		return <div>Loading...</div>;
	}

	// 데이터 로딩 에러
	if (error) {
		return <div>Error: {error.message}</div>;
	}
	return (
		<Layout className='erp' title='ERP' breadcrumbs={breadcrumbs}>
			<div className='erp_cont'>
				<h2 className='title'>ERP 소개</h2>
				<p>
					<span className='main_c'>ERP(Enterprise Resource Planning)</span> 란 기업 활동을 위해 사용되는 기업 내 모든 인적자원/물적자원을 효율적으로 관리하여 기업의 경쟁력을 강화시켜주는 역할을 하는
					통합 정보 시스템을 의미합니다. 즉, 기업 각 부문에 걸친 ‘정보’라는 경영자원을 하나의 체계 아래 통합해 관리하는 시스템으로서, 기업의 전부문 간 데이터 공유를 통해 협업이 용이해지고, 덕분에
					인력, 설비, 자재, 정보, 시간 등의 경영자원을 최적화하여 오늘날 기업 내 중요 의사결정에 활용하는 수단입니다.
				</p>
			</div>
			<div className='erp_cont'>
				<h2 className='title'>ERP 효율성</h2>
				<div className='erp_cont_desc'>
					<div className='txt_desc'>
						<p>
							<strong>운영 측면에서 비부가가치적인 작업을 배제할 수 있습니다.</strong>
							BPR의 지원으로 모든 업무 프로세스를 통합적으로 진행하여 중복 업무 제거와 오류 최소화가 가능합니다. 이러한 운영 효율성을 바탕으로 업무 시간을 단축하고 필요한 인력 및 자원을 절약할 수
							있습니다.
						</p>
						<p>
							<strong>다양한 정보 획득 및 활용으로 업무 효율을 높일 수 있습니다.</strong>
							정보 공유를 통해 기업 구성원들은 정확한 데이터를 신속하게 확인할 수 있으며, 이를 통해 여러 측면에서 정보를 활용할 수 있습니다.
						</p>
						<p>
							<strong>최신 데이터의 실시간 제공을 통해 맞춤형 보고서 작성이 가능합니다.</strong>
							정보 기반의 의사결정을 할 때 조직에 도움이 되며, 비즈니스 환경의 변화에 중요한 역할을 합니다. 이러한 맞춤형 접근 방식으로 KPI를 측정하고 다양한 비즈니스 구성 요소의 성과를 추적할 수
							있습니다.
						</p>
					</div>
					<div className='img_desc'></div>
				</div>
			</div>
			<div className='erp_cont'>
				<h2 className='title'>ERP 기대 효과</h2>
				<div className='info_box_all'>
					{data?.erpEffects.map((effect, index) => (
						<div className='info_box' key={index}>
							<h3>{effect.title}</h3>
							<p>{effect.description}</p>
						</div>
					))}
				</div>
			</div>
		</Layout>
	);
}
